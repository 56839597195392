<template>
    <div class="wrapper">

      <router-view></router-view>

        <!-- HeaderNavSix section -->
        <HeaderNav></HeaderNav>

        <div class="main-content">

            <!-- Breadcrumb section -->
            <!-- <Breadcrumb breadcrumbTitle="Error 404" breadcrumbName="404"></Breadcrumb> -->

            <!-- ErrorSection section -->
            <ErrorSection></ErrorSection>

        </div>

        <!-- Banner section -->
        <BannerTwo></BannerTwo>

        <!-- Footer section -->
        <FooterSection></FooterSection>

    </div>
</template>

<script>
import HeaderNav from '@/components/HeaderNav.vue'
import Breadcrumb from '@/components/Breadcrumb.vue'
import ErrorSection from '@/components/ErrorSection.vue'
import BannerTwo from '@/components/BannerTwo.vue'
import FaqSectionTwo from '@/components/FaqSectionTwo.vue'
import FooterSection from '@/components/FooterSection.vue'

export default {
  components: {
    HeaderNav,
    Breadcrumb,
    ErrorSection,
    BannerTwo,
    FaqSectionTwo,
    FooterSection
  }
}
</script>